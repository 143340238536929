/* eslint-env jquery */
/* global jQuery3, Drupal, classie */

;(function ($, Drupal) {
  Drupal.behaviors.pac5 = {
    attach: function (context, settings) {
      this.addTableWrapper(context)
      this.autostartVideo(context)
      this.addActiveTrail(context)
      this.expandableMenu(context)

      $('body.front .slider ul').slick({
        arrows: false,
        autoplay: true,
        fade: true,
        autoplaySpeed: 10000
      })
    },

    addTableWrapper: function (context) {
      $('table', context).each(function () {
        if ($(this).hasClass('sticky-header')) return

        if (!$(this).parent().hasClass('table-mobile-wrapper')) {
          $(this).wrapAll('<div class="table-mobile-wrapper"/>')
        }
      })
    },

    autostartVideo: function (context) {
      if (context !== document) {
        return
      }

      setTimeout(function () {
        $('body.page-video-embed-node video').each(function () {
          var video = $(this)[0].player
          if (typeof video !== 'undefined') {
            video.pause()

            // https://bugs.chromium.org/p/chromium/issues/detail?id=593273
            setTimeout(function () {
              video.play()
            }, 150)
            video.on('ended', function () {
              video.play()
            })
          }
        })
      })
    },

    addActiveTrail: function (context) {
      if (!$('body.node-type-quiz .opigno-group-progress-course', context).length) return

      $('body.node-type-quiz .opigno-group-progress-course > div > .item-list', context).each(function () {
        var $itemList = $(this)
        var $children = $itemList.children('ul').children('li')

        $children.each(function () {
          if ($(this).find('a.active').length) {
            $(this).children('a')
              .addClass('active-trail')
              .addClass('subMenuOpen')
          }
        })
      })
    },

    expandableMenu: function (context) {
      if (!$('body.node-type-quiz .opigno-group-progress-course', context).length) return

      $('.opigno-group-progress-course > div > .item-list > ul > li > a', context).click(function (e) {
        var hasChild = $(this).next('.item-list').length
        if (hasChild && $(this).hasClass('subMenuOpen')) {
          e.preventDefault()
          $(this).removeClass('subMenuOpen')
          $(this).next('.item-list').hide()
        } else if (hasChild) {
          e.preventDefault()
          $(this).addClass('subMenuOpen')
          $(this).next('.item-list').show()
        }
      })
    }
  }
})(jQuery3, Drupal)

function init () {
  window.addEventListener('scroll', function (e) {
    var distanceY = window.pageYOffset || document.documentElement.scrollTop
    var shrinkOn = 300
    var header = document.querySelector('header')
    var body = document.querySelector('body')

    if (typeof classie === 'undefined') {
      return
    }

    if (distanceY > shrinkOn) {
      classie
        .add(header, 'smaller')
        .add(body, 'smaller-header')
    } else if (classie.has(header, 'smaller')) {
      classie
        .remove(header, 'smaller')
        .remove(body, 'smaller-header')
    }
  })
}
window.onload = init()
