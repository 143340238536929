/* global angular */

var app = angular.module('App', ['duScroll'])

app.controller('ctrlController', ['$scope', '$document', '$timeout', function ($scope, $document, $timeout) {
  var ctrl = this
  ctrl.scrollDuration = 200

  ctrl.scrollTop = function () {
    $document.duScrollTop(0, this.scrollDuration)
  }
}])
